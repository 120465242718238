.nav-link {
  .svg-inline--fa {
    width: 1em;
  }
}

.btn-secondary,
.p-button {
  color: #fff;
  background-color: $nav-dark;
  border: $nav-dark;

  &:hover {
    background-color: $focus-green;
    border-color: $focus-green;
    color: #fff;
  }
}


.navbar-toggler {
  border: 0;

  &:hover {
    @include navigation-focus();
  }
}

.p-accordion-header-link,
.p-tabview-nav-link {
  color: #000 !important;
  text-decoration: none;

  &:hover {
    color: $focus-green;
  }
}

.p-dropdown-items-wrapper {
  .p-dropdown-items {
    padding-left: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include text();
}

.p-carousel-indicators {
  padding: 0 !important;

}

.p-carousel-indicator {

  .p-link {
    background-color: $focus-green;
  }
}

.p-carousel-next,
.p-carousel-prev {
  background-color: transparent;
}

a {
  color: $focus-green !important;
}

#topbar {
  a {
    color: #000 !important;
  }
}

.navbar-nav {
  a {
    color: #fff !important;
  }
}

// Custom pagination styles
.p-paginator {
  border: none;
  padding: 1rem;
  border-radius: 8px;

  .p-paginator-pages {
    .p-paginator-page {
      color: $nav-dark;
      border-radius: 4px;
      min-width: 2.5rem;
      height: 2.5rem;

      &.p-highlight {
        background: $focus-green;
        color: white;
      }

      &:hover:not(.p-highlight) {
        background: darken($site-main, 5%);
      }
    }
  }

  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    color: $nav-dark;
    border-radius: 4px;
    min-width: 2.5rem;
    height: 2.5rem;

    &:hover:not(.p-disabled) {
      background: darken($site-main, 5%);
    }

    &.p-disabled {
      opacity: 0.5;
    }
  }

  .p-dropdown {
    background: white;
    border-color: darken($site-main, 10%);

    &:hover:not(.p-disabled) {
      border-color: $focus-green;
    }

    &.p-focus {
      box-shadow: 0 0 0 2px rgba($focus-green, 0.2);
      border-color: $focus-green;
    }
  }

  .p-paginator-current {
    color: $nav-dark;
  }
}
