.cover-upload {
  .p-fileupload {
    .p-fileupload-buttonbar {
      display: none;
    }

    .p-fileupload-content{
      height: 10rem;
    }
  }
}
