@mixin navigationToggled() {
  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    .nav-link {
      color: $site-main;
      text-align: center;
      padding: 0.75rem 1rem;
      width: $sidebar-collapsed-width;

      span {
        font-size: 0.65rem;
        display: block;
      }

      &:hover,
      &.active {
        @include side-navigation-focus();
      }
    }
  }

  .brand {
    img {
      width: calc($sidebar-collapsed-width - 1rem);
    }
  }


}


.sidebar {
  @include text();
  background-color: $nav-dark;
  width: $sidebar-collapsed-width;
  min-height: 100%;
  flex-direction: column;
  transition: all 0.3s;


  .brand {
    height: $topbar-base-height;
    text-align: center;

    img {
      height: calc($topbar-base-height - 1rem);
    }
  }

  .sidebar-section {
    &:not(:has(.nav-link)) {
      display: none;
    }

    .sidebar-heading,
    .sidebar-divider {
      display: none;
      color: $site-main;
      padding: 0 1rem;
      font-weight: 800;
      font-size: .85rem;
    }
  }

  @include navigationToggled();

  &.collapsed {
    width: 0 !important;
    overflow: hidden;
  }
}

@include media-breakpoint-up(md) {
  .sidebar {
    width: $sidebar-base-width;

    .sidebar-section {

      .sidebar-heading,
      .sidebar-divider {
        display: block;
      }
    }

    .brand {
      img {
        width: calc($sidebar-base-width - 6rem);
      }
    }

    .navbar-nav {
      .nav-link {
        display: block;
        width: 100%;
        text-align: left;
        padding: .5rem 1rem;
        width: $sidebar-base-width;

        .nav-icon {
          font-size: 0.85rem;
          margin-right: 0.5rem;
        }

        span {
          font-size: 0.85rem;
          display: inline;
        }
      }
    }

    &.collapsed {
      width: $sidebar-collapsed-width !important;
      @include navigationToggled();

      .sidebar-section {

        .sidebar-heading,
        .sidebar-divider {
          display: none;
        }
      }
    }


  }

}
