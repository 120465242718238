/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,700&family=Source+Code+Pro:wght@500&display=swap');

@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

@import '../node_modules/ngx-sharebuttons/themes/default';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import 'styles/variables';
@import 'styles/reboot';
@import 'styles/components/table';
@import 'styles/admin';
@import 'styles/main';
@import 'styles/authenticated';

html {
  position: relative;
  min-height: 100%;
}

body {
  min-height: 100%;
  height: 100%;
  margin: 0;
  @include text;
}

.nav-background {
  background-image: url('./assets/images/sv_header_bg.png');
  // background-size: cover;
  // min-height: 100%;
}

a {
  color: #a12426;
}

.card-header {
  margin: 0 !important;
}

.highlight {
  text-decoration: underline;

  &:hover {
    color: blue;
    cursor: pointer;
    text-decoration: none;
  }
}

main {
  padding-top: 1.5em;
  padding-bottom: 1em;
}

h1 {
  padding-bottom: 0.5em;
}

// Utility class to hide arrow from dropdown
.dropdown.no-arrow {
  .dropdown-toggle::after {
    display: none;
  }
}

.p-inputtext {
  width: 100%;
}

p-calendar,
p-inputMask,
p-autocomplete {

  >span {
    width: 100%;

    >input {
      height: calc(3.5rem + 2px);
      line-height: 1.25;
      padding: 1rem .75rem;
      color: #212529 !important;
      width: 100%;
    }
  }
}

.p-autocomplete-multiple-container {
  width: 100%;
  height: calc(3.5rem + 2px);
  line-height: 1.25;
  padding: 1rem .75rem;
}

.content-item-container {
  .form-floating {
    height: unset !important;
  }
}

form {
  .form-floating {
    height: 100%;

    p-inputMask {
      >input {
        width: 100%;
        padding: 1rem 0.75rem;
        padding-top: 1.625rem;
        padding-bottom: 0.625rem;
      }
    }

    p-inputMask,
    p-autocomplete,
    p-calendar {
      >span {
        >input {
          width: 100%;
          padding: 1rem 0.75rem;
          padding-top: 1.625rem;
          padding-bottom: 0.625rem;
        }
      }

      ~label {
        opacity: 0.65;
        transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
      }

      &.is-invalid {
        >span {
          >input {
            @include is-invalid();
          }
        }
      }
    }

    p-dropdown {
      >div {
        width: 100%;
        height: 100%;

        >span {
          width: 100%;
          padding: 1rem 0.75rem;
          padding-top: 1.625rem;
          padding-bottom: 0.625rem;
        }
      }

      ~label {
        opacity: 0.65;
        transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
      }

      .is-invalid {
        >span {
          @include is-invalid();
        }
      }
    }

    .p-inputwrapper {
      width: 100%;

      .p-autocomplete-input {
        width: 100%;
        padding: 1rem 0.75rem;
        padding-top: 1.625rem;
        padding-bottom: 0.625rem;
      }
    }


  }
}

.p-tabview-nav-link:has(.is-header-invalid) {
  border-color: #dc3545 !important;
}

.is-header-invalid {
  color: #dc3545;
}

.gjs-mdl-dialog {
  max-width: 75%;
  width: 100%;
}


/* Start New Navbar */

.site-nav-wrapper {
  background-color: $nav-dark !important;
  color: #fff;
}

footer {
  background-color: $nav-dark;
}

.scroll-top {
  position: fixed;
  bottom: 5px;
  right: 5px;
  font-size: 20px;
  text-align: center;
  border: 0;
  background-color: $nav-dark;
  color: #fff;
  outline: none;

  &:hover {
    background-color: $focus-green;
  }
}

.p-breadcrumb {
  background-color: transparent;
  padding: 0;
}

.p-breadcrumb-list {
  padding-left: 0;
  margin-bottom: .25rem !important;

  .p-menuitem-link {
    text-decoration: none;
  }
}


.error-container {
  background-image:
    /* top, transparent red */
    linear-gradient(rgba(255, 255, 255, 0.45),
      rgba(255, 255, 255, 0.45)),
    /* your image */
    url('assets/images/snooker_bg.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;

  img {
    width: 15rem;
    margin-right: 1rem;
  }

  .divider {
    border: 1px solid $nav-dark;
  }

  .lead-container {
    padding-top: 3rem;

    .lead {
      font-size: 100px;
      padding: 0 !important;
      margin-left: 1rem;
    }
  }

  @include media-breakpoint-up(lg) {
    img {
      width: 30rem;
    }

    .lead-container {
      padding-top: 0;

      .lead {
        font-size: 200px;
      }
    }
  }
}

table.player-detail-interclub-games {
  th {
    background-color: transparent;
  }

  td {
    background-color: transparent;

    &.highlight {
      color: $focus-green;
      text-decoration: none;
      font-weight: bold;
    }
  }

  tr.detail-row {
    &:hover {
      background-color: $focus-green !important;
      cursor: pointer;

      td, a{
        color: #fff !important;
        font-weight: bold;
      }
    }
  }

}
