@import '~quill/dist/quill.snow.css';

@import "admin/general";
@import "admin/navbar";
@import "admin/sidebar";

@import "components/fileupload";

@import "admin/content";

.admin-area-wrapper {
  @include text();
}

.sticky-footer {
  background-color: $site-main;
}

.quill-editor {
  width: 100%;
  height: 30rem;

  .ql-toolbar {
    background-color: white;
  }

  .ql-editor {
    background-color: white;
  }
}

.content-wrapper {
  padding: 1rem;

  .card {
    padding: 2rem;
  }

  .admin-button-container{
    margin-bottom: 1rem;
  }

  .admin-title {
    .title {
      font-size: 1rem !important;
      font-weight: bold;
    }
  }

  .admin-card {
    width: 10rem;

    .card {
      padding: 0;
      margin-right: 0.2rem;
      margin-top: 0.2rem;

      .card-title {
        font-size: .7rem;
        word-wrap: break-word;
      }
    }

    &:hover {
      .card {
        background-color: $focus-green;
        border: 1px solid $focus-green;
        color: white;
      }
    }
  }
}
