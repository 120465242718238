.table-search {
  .input-group-text {
    background-color: transparent;
    border: transparent;
  }
}

/*clickable row */
table.detail {
  --bs-table-bg: none !important;

  tbody {
    tr.detail-row {
      &:has(.fw-bold) {
        &:hover {
          background-color: $focus-green !important;
          cursor: pointer;

          td, a{
            color: #fff !important;
            font-weight: bold;
          }
        }

      }

      a {
        text-decoration: none;
      }
    }
  }
}
