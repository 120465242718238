$nav-dark: #1d252d;
$site-main: #edeff4;

$focus-green: #15b095;
$menu-highlight-color: #E73332;

$news-item-background: #f2f2f1;



:root {
  --focus-green: $focus-green;
  --focus-red: $menu-highlight-color;
  --focus-green-darken: darken($focus-green, 10%);
  --focus-red-darken: darken($menu-highlight-color, 10%);
}

$topbar-base-height: 4.375rem !default;
// Change below variable to change the width of the sidenav
$sidebar-base-width: 14rem !default;
// Change below variable to change the width of the sidenav when collapsed
$sidebar-collapsed-width: 6.5rem !default;

@mixin text {
  --font-family: "Poppins", sans-serif !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

@mixin text-bold {
  --font-family: "Poppins", sans-serif !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
  font-style: normal;
}

@mixin navigation-focus-dropdown {
  font-weight: bold;
  color: $focus-green !important;
  border: 0 !important;
  @include text-bold();
}

@mixin navigation-focus {
  font-weight: bold;
  color: #fff !important;
  border-bottom: 1px solid $menu-highlight-color;
  @include text-bold();
}

@mixin side-navigation-focus {
  color: $menu-highlight-color !important;
  @include text-bold();
}

@mixin userProfileMenu {
  .img-profile {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@mixin is-invalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

@mixin height($height) {
  height: $height !important;
}

@mixin width($width) {
  width: $width !important;
}

@mixin horizontalRule($width, $color) {
  hr {
    border-top: $width solid $color;
  }
}
