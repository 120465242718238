.club-district {
  .p-accordion {
    margin-top: 1rem;
  }

  .interclub-team-header {
    display: flex;
    justify-content: space-between;
  }

  .interclub-team-player-row {
    display: flex;
    justify-content: space-between;
    padding: 5px;
  }

  .interclub-team-player-row:nth-of-type(even) {
    background-color: #eeeeee;
  }

}

.player-detail-top {
  background-color: $nav-dark;
  padding: 1rem;
  color: #fff;

  .player-image {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    height: 100%;
    width: 100%;
    background-color: $nav-dark;

    /* Ensure the container has a defined height */
    img {
      border: 1px solid #fff;
      max-width: 100%;
      /* Optional: Ensure the image does not overflow its container */
      max-height: 100%;
      /* Optional: Adjust this as needed */
      width: 15rem;
    }




  }

  .player-info-main {
    .player-info-title {
      font-size: 2rem;
      font-weight: bold;
    }

    .player-info-table {
      margin-top: 1rem;
      width: 100%;
      border-collapse: collapse;
      border: 0 !important;

      tr {
        &:nth-child(3) {
          border-top: 2px solid $focus-green;
        }
      }

      strong,
      td {
        color: #fff;
      }

      tbody {
        width: 100%;

        tr:nth-child(2) {

          // Targeting the second row
          td {
            padding-bottom: 2rem; // Adjust the padding value as needed
          }
        }

        tr:nth-child(3) {

          // Targeting the third row
          td {
            padding-top: 2rem; // Adjust the padding value as needed
          }
        }
      }

      td {
        width: 22.5%;
        text-align: left;
        background-color: $nav-dark !important;

        &:nth-child(even) {
          text-align: right;
        }

        .spacer-left{
          margin-left: 2rem;
        }

        .spacer-right{
          margin-right: 2rem;
        }

        hr {
          border-top: 2px solid $focus-green;
        }
      }

      @include media-breakpoint-down(md) {

        table,
        thead,
        tbody,
        th,
        tr,
        td {
          display: block;
        }

        /* Make each td take up 50% width on small screens */
        tr {
          display: flex;
          flex-wrap: wrap;
        }

        td {
          width: 50%;
          /* Adjusted width */
          box-sizing: border-box;
          padding: .5rem 0 !important;

          &:nth-child(odd) {
            text-align: left;
          }
        }

        td {
          padding: 8px;
          text-align: right;
        }

        td:first-child {
          font-weight: bold;
          border-top: none;
        }

        /* Adjustments for td:before for responsive labels, if needed */
        td:before {
          content: attr(data-label);
          float: left;
          font-weight: bold;
        }
      }

    }
  }

  .season-history {}


}

.player-statistic-box {
  padding: 1rem;
  background-color: #f2f2f1;
  border-bottom: $focus-green 2px solid;

  hr {
    color: $focus-green;
  }

  .player-statistic-table {
    width: 100%;

    tbody {
      width: 100%;
    }

    td {
      width: 50%;
    }
  }
}

.member-detail-tab{
  a:not(.p-accordion-header-link){
    color: $focus-green !important;

    &:hover{
      color: $menu-highlight-color !important;
      font-weight: bold;
    }
  }
}
