.site-nav {
  background-color: $nav-dark;
  color: #fff;

  .container-fluid {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  // Left section - Logo
  .nav-section-left {
    flex: 0 0 auto;

    .navbar-brand {
      padding: 0.5rem 0;

      img {
        height: 60px;
      }
    }
  }

  // Center section - Main navigation
  .nav-section-center {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;

    .navbar-nav {
      gap: 0.5rem;
    }
  }

  // Common link styles
  .nav-link {
    color: #fff;
    text-decoration: none;
    transition: all 0.2s ease;
    padding: 0.5rem 1rem;
    white-space: nowrap;

    &:hover,
    &.active {
      @include navigation-focus();
    }
  }

  // Override for dropdown toggles
  .dropdown {
    .nav-link.dropdown-toggle {
      &:hover,
      &.active {
        color: $menu-highlight-color !important;
        border-bottom: none;
      }
    }
  }

  // Right section - Districts + User Menu
  .nav-section-right {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-left: auto;

    // Districts navigation
    .districts-nav {
      position: relative;

      .navbar-nav {
        flex-direction: row;
        align-items: center;
      }

      .nav-link {
        display: flex;
        align-items: center;
        padding: 0.5rem;
        white-space: nowrap;

        &.dropdown-toggle {
          &:hover,
          &.active {
            color: $menu-highlight-color !important;
            border-bottom: none;
          }
        }
      }

      .dropdown-menu {
        position: absolute;
        z-index: 1050;
        background-color: $nav-dark;
        border: 1px solid rgba(255, 255, 255, 0.1);
        min-width: 200px;
        padding: 0.5rem 0;

        .dropdown-item {
          color: #fff;
          padding: 0.5rem 1rem;

          &:hover, &.active  {
            background-color: rgba(255, 255, 255, 0.1);
            color: $menu-highlight-color !important;
          }
        }

        .dropdown-divider {
          border-color: rgba(255, 255, 255, 0.1);
          margin: 0.25rem 0;
        }
      }
    }

    // User menu and toggler container
    .user-menu-container {
      position: relative;
      display: flex;
      align-items: center;
      gap: 0.75rem;

      .user-menu {
        position: relative;

        .navbar-nav {
          flex-direction: row;
          align-items: center;
        }

        #user-nav {
          position: relative;

          .nav-link {
            padding: 0.5rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;

            &.dropdown-toggle {
              &:hover,
              &.active {
                color: $menu-highlight-color !important;
                border-bottom: none;
              }
            }
          }

          .dropdown-menu {
            position: absolute;
            z-index: 1050;
            background-color: $nav-dark;
            border: 1px solid rgba(255, 255, 255, 0.1);
            margin-top: 0.5rem;
            min-width: 200px;
            right: 0;
            left: auto;
            top: 100%;
            transform-origin: top right;

            .dropdown-item {
              color: #fff;
              padding: 0.5rem 1rem;

              &:hover {
                background-color: rgba(255, 255, 255, 0.1);
                color: $menu-highlight-color !important;
              }
            }

            .dropdown-divider {
              border-color: rgba(255, 255, 255, 0.1);
              margin: 0.25rem 0;
            }
          }

          .img-profile {
            width: 32px;
            height: 32px;
            object-fit: cover;
            border: 2px solid rgba(255, 255, 255, 0.2);
          }
        }
      }

      // Mobile Toggle Button
      .navbar-toggler {
        color: #fff;
        border: none;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          color: $menu-highlight-color;
        }
      }
    }
  }

  // Offcanvas styles
  .offcanvas {
    background-color: $nav-dark !important;

    .menu-title {
      display: none;
      color: #fff;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    .offcanvas-close {
      display: none;
      float: right;
      color: #fff;
      padding: 0.5rem;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        color: $menu-highlight-color;
      }
    }

    .offcanvas-body {
      padding: 1rem;
    }

    &.show {
      .offcanvas-close,
      .menu-title {
        display: block;
      }
    }
  }

  // Mobile Districts Navigation
  .districts-nav-mobile {
    .accordion {
      background: transparent;

      .accordion-item {
        background: transparent;
        border: none;

        .accordion-button {
          background: transparent;
          color: #fff;
          padding: 1rem 0.5rem;
          border: none;
          box-shadow: none;

          &:not(.collapsed) {
            color: $menu-highlight-color;
          }

          &::after {
            filter: brightness(0) invert(1);
          }
        }

        .accordion-body {
          padding: 0.5rem;
        }
      }
    }

    .mobile-menu-item {
      color: #fff;
      padding: 0.5rem 1rem;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover,
      &.active {
        background-color: rgba(255, 255, 255, 0.1);
        color: $menu-highlight-color;
      }
    }
  }

  // Mobile styles
  @media (max-width: 767px) {
    .user-menu-container {
      margin-left: auto;

      .user-menu {
        #user-nav {
          .dropdown-menu {
            position: absolute;
            top: 100%;
            right: 0;
            left: auto;
            z-index: 1050;
            transform-origin: top right;
          }
        }

        .img-profile {
          width: 36px;
          height: 36px;
        }
      }
    }

    .districts-nav-mobile {
      margin-top: 1rem;
    }
  }
}

.accordion-header{
  margin: 0 !important;
}
