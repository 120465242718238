@import "components/loader";
@import "components/newsitem";

@import "main/navbar";
@import 'main/members';

.title-container {
  display: flex;
  align-items: center;
  /* Align items vertically in the center */
  justify-content: space-between;
  border-bottom: 1px $focus-green solid;
  padding-bottom: 10px;
  margin-bottom: 1rem;

  /* Align items from the start, icon will be on the left */
  .title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  &.sub-title {
    padding-bottom: 5px;

    .title {
      font-size: 1rem;
      font-weight: bold;
    }
  }
}

.footer {
  background-color: $nav-dark;

  h5 {
    color: white;
    margin-bottom: 1.25rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-size: 1rem;
    position: relative;
    padding-bottom: 0.75rem;

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      height: 2px;
      width: 40px;
      background-color: $focus-green;

      @media (min-width: 768px) {
        left: 0;
        transform: none;
      }
    }
  }

  .nav-link {
    transition: color 0.2s ease;

    &:hover {
      color: $focus-green !important;
    }
  }

  small {
    color: rgba(255, 255, 255, 0.8);
  }

  .border-top {
    border-color: rgba(255, 255, 255, 0.1) !important;
  }

  img {
    transition: opacity 0.2s ease;
    vertical-align: middle;

    &:hover {
      opacity: 0.8;
    }
  }
}
