.file-upload {
  cursor: pointer;

  &:hover {
    fa-icon {
      color: $focus-green;
    }

    span {
      font-weight: bold;
      color: $focus-green;
    }
  }

  &.toolbar-enabled {
    .p-fileupload-buttonbar {
      display: block;
    }
  }

  .p-fileupload-buttonbar {
    display: none;
  }

  .p-fileupload-content {
    height: 10rem;
    padding: 0%;

    .image-container {
      @include height(10rem);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f5f5f5;
      border: 1px solid #e4e4e4;
      border-radius: 0.25rem;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
