/* general */
hr.sidebar-divider {
  margin: 0 1rem 1rem;
  border-top: 1px solid $site-main;
}

.shadow {
  box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
}

.admin-area-wrapper {
  display: flex;
  min-height: 100vh;
  background-color: #edeff4;


  .admin-area-content-wrapper {
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;

    .content {
      flex: 1 0 auto;
      padding: 0 1rem;
    }

  }
}

.action-menu {
  button {
    margin-left: 0.5rem;
  }
}