.news-item {
  padding: 10px;
  background-color: $news-item-background;
  border-bottom: solid 1px $focus-green;
  // Default layout styles here

  &.pinned {
    background-color: $nav-dark;
    display: flex;
    flex-direction: column;


    .top-section {
      display: flex;
      flex-direction: row;
      justify-content: center; // Keeps content centered
      align-items: start; // Keeps content vertically centered

      .cover-image-container {
        flex: 1;
        // Additional styling for the image
        height: 100%;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border: 1px solid #fff;
        }
      }

      .title-and-content {
        flex: 1;
        color: #fff;

        h2 {
          margin: 0;
          font-size: 2rem;
          padding-bottom: 0.25rem;
        }

        button {}

        // Additional styling for the content
        .news-content {
          display: block;
        }
      }
    }

    .by-line {
      color: #fff !important;
      margin-top: 0.2rem;
      // Styling for bylines
    }
  }

  .title-and-content {
    .news-content {
      display: none;
      font-size: 0.7rem;
      font-weight: normal;
    }

    button {
      float: right;
      margin: 0.25rem 0;
      background-color: $focus-green;

      &:hover {
        background-color: darken($focus-green, 10%);
      }
    }
  }

  a {
    text-decoration: none;
    color: $nav-dark !important;
  }

  h2 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
  }

  .cover-image-container {
    height: 10rem;
    display: flex; // Establish as flex container
    justify-content: center; // Center horizontally
    align-items: center; // Center vertically

    .cover-image {
      width: auto; // Keep original width
      height: auto; // Keep original height
      max-height: 100%; // Ensure it doesn't exceed the container's height
      display: block; // Display as block
      margin: auto; // Center horizontally if width is less than container's width
    }
  }

  .bylines {
    flex: 1; // Allows news content to grow and fill available space
    display: flex; // Establish as flex container for its children
    flex-direction: row; // Stack children horizontally
    justify-content: space-between; // Distribute space between by-line items

    .by-line {
      white-space: nowrap; // Prevent by-line from wrapping
      overflow: hidden; // Hide overflow
      text-overflow: ellipsis; // Add ellipsis to overflowed text
      font-size: 0.7rem;
      text-align: right;
    }
  }

}

.content-item-detail {
  .title {
    font-size: 2rem !important;
  }

  @include horizontalRule(2px, $focus-green);

  .cover-image {
    background-size: cover;
    background-position: center;
    width: 100%;

    img {
      object-fit: cover;
      height: 20rem;
      width: 100%;
    }
  }

  .metadata-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    .metadata {
      display: flex;
      align-items: start;
      flex-direction: column;

      .metadata-item-wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-bottom: 0.5rem;

        .metadata-item {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
