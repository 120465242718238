#topbar {
    height: $topbar-base-height;
    background-color: #fff;

    @include userProfileMenu();

    .btn-collapse-side {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;

        &:hover {
            color: #fff;
            background-color: $focus-green;
        }
    }
}
